@import '../variables';

#mainNav {
    @extend %flex-row-center;
    justify-content: end;
    width: 100%;

    position: fixed;
    top: 0;

    .navItem {
        color: #ffffff;
        font-size: 120%;
        text-decoration: none;
        border-radius: 5px;
        padding: $pd-s 7*$pd-xs;
        margin: $mg-s 2*$mg-s;

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
}
