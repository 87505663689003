@import '../variables';

#hero {
    @extend %flex-col-center;
    justify-content: center;

    margin-top: -$mg-l;
    background: $clr-purple url('../media/pattern-transp.png') repeat center;
    background-size: 400px;
    color: white;

    &.small {
        height: 40vh;
    }

    &.medium {
        height: 80vh;
    }

    &.large {
        height: 95vh;
    }

    #logo {
        width: 40%;
        margin-bottom: $mg-xl;
    }
}

.heroPointer {
    @extend %flex-col-center;
    height: 2.5*$mg-m;

    span {
        width: 0;
        height: 0;
        border-left: 2*$mg-m solid transparent;
        border-right: 2*$mg-m solid transparent;

        border-top: 2*$mg-m solid $clr-purple;

        animation-duration: 3s;
        animation-name: bounce;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
    }
}

@keyframes bounce {
    0%, 76%, 86%, 90%, 100% {
        border-top: 2*$mg-m solid $clr-purple;
    }
    80%, 94% {
        border-top: 2.5*$mg-m solid $clr-purple;
    }
}

@media only screen and (max-width: 600px) {
    #hero {
        #logo {
            width: 80%;
            margin: $mg-s 0 $mg-l;
        }

        h4 {
            font-size: 120%;
        }
    }
}
