@import '../variables';

#footer {
    @extend %flex-col-center;

    h1 {
        margin: 0 $mg-l $mg-l;
    }

    p {
        padding: $pd-s $pd-m;
        text-align: center;
    }

    .socials {
        @extend %flex-row-center;
        font-size: 300%;


        a {
            color: $clr-purple-dark;
            margin: $mg-m $mg-l;
            transition: color 500ms;
            animation: shake 5s ease infinite;

            &:hover {
                color: $clr-grey-lighter;
                cursor: pointer;
                transition: color 0ms;
            }
        }
    }
}

@keyframes shake {
    0%, 20% {
        transform: translateX(0);
    }
    2%, 18% {
        transform: translateX(-1px) translateY(0) rotate(-1deg);
    }
    4%, 16% {
        transform: translateX(2px) translateY(-1px) rotate(2deg);
    }
    6%, 10%, 14% {
        transform: translateX(-3px) translateY(-1px) rotate(-3deg);
    }
    8%, 12% {
        transform: translateX(3px) translateY(-3px) rotate(3deg);
    }
}
