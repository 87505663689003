@import "~bootstrap/scss/bootstrap";
@import './variables';

/* CSS RESET */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr,
th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
}

root {
    @extend %flex-row;
}


@media only screen and (max-width: 600px) {
    body {
        font-size: 16px;
    }

    .not-on-mobile {
        display: none;
    }

    .mobile-only {
        display: initial;
    }
}

@media only screen and (min-width: 600px) {
    .not-on-mobile {
        display: initial;
    }

    .mobile-only {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    body {
        font-size: 18px;
    }
}

h1, h2, h3, h4 {
    text-align: center;
}

.spacing-top-l {
    margin-top: $mg-l;
}

.spacing-top-xl {
    margin-top: $mg-xl;
}

.spacing-top-xxl {
    margin-top: 4*$mg-xl;
}
