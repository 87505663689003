@import '../variables';

.mainContainer {
    display: inline-block;
    min-height: 100vh;
    width: 100%;

    background-color: $clr-purple-lightest;
}

#home {

}

.blockRow {
    @extend %flex-row-center;
    justify-content: space-around;
    width: 80%;
    max-width: 600px;
}

.block {
    @extend %flex-row-center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    font-size: 160%;
    color: $clr-yellow;
    background-color: $clr-purple-dark;
}

.marker {
    background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=FFD700);

    &[data-brush="5"] {
        background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=FFD700);
    }

    &[data-brush="9"] {
        background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-9&color=FFD700);
    }
}

.days {
    @extend %flex-row-center;
    align-items: start;
    justify-content: center;

    > div {
        width: 40%;
        margin: $mg-l 2*$mg-m;
    }

    > :after {
        z-index: -2;
    }
}

.dayContainer {
    @extend %flex-col-center;
    width: 40%;
    color: $clr-purple-lightest;
    padding: 6*$pd-s $pd-s;

    h1 {
        color: $clr-yellow;
        padding: $pd-s 0;
        font-size: 150%;

        &:after {
            display: block;
            content: '';

            margin: 2*$mg-s -3*$mg-m $mg-m;
            height: 3px;
            background: radial-gradient($clr-purple-lighter 0%, $clr-purple 60%, shaded($clr-purple, 30) 80%);
        }
    }

    ul {
        display: inline-block; // auto-width
        list-style-type: none;
        padding-left: 2*$pd-m;
        padding-bottom: $pd-m;

        li {
            padding-bottom: $pd-s;

            svg {
                padding-right: 3*$pd-s;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .dayContainer h1:after {
        margin: 2*$mg-s 0 $mg-m;
    }
}

@media only screen and (max-width: 600px) {
    .days {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 2*$pd-s;

        > div {
            width: 100%;
            margin: $mg-l 0;
        }
    }
    .dayContainer {
        h1:after {
            margin: 2*$mg-s 0 $mg-m;
        }
    }
}
