@import '../variables';

.infoDiv {
    margin-bottom: $mg-m;
}

#timetable {
    @extend %flex-col;
    width: 90%;
    min-width: 600px;
    max-width: 1100px;
    margin: 0 auto $mg-m;
    padding: 0 $pd-m;
}

#timetableHeader {
    @extend %flex-row;
    width: 100%;
    height: 50px;

    padding-left: 50px; // width hourColumn
    background-color: $clr-purple-dark;
    border-bottom: 2px solid $clr-purple-darker;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;

    .eventColumnTitle {
        display: flex;
        flex: 1 1 0;
        align-items: center;
        justify-content: center;
    }
}

#timetableContent {
    @extend %flex-row;
    font-size: 80%;
    background-color: white;

    #hourColumn {
        @extend %flex-col-center;
        width: 50px;
        height: 100%;
        text-align: center;
        padding: 0 5px;
        background-color: $clr-purple-dark;
        color: $clr-purple-light;

        span {
            border-bottom: 1px solid $clr-purple;
            width: 100%;

            &:last-of-type {
                border-bottom: none;
            }
        }
    }

    .eventColumn {
        flex-grow: 1;
        border-left: 1px solid faded($clr-purple, 0.2);
        padding: 2px 4px;
        position: relative;

        .event {
            @extend %flex-col;
            position: absolute;
            width: 90%;
            margin: 0 5%;
            padding: 8*$pd-xs 3*$pd-s;

            border-radius: 8px;
            background-color: faded($clr-purple-lighter, 0.8);

            .eventTop {
                @extend %flex-row;
                justify-content: space-between;
            }

            .eventTitle {
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .eventTime {
                font-weight: 300;
                opacity: 0.5;
            }

            .eventDescription {
                font-weight: 200;
                font-size: 90%;
                margin-top: 3*$mg-xs;
            }

            &.smallPadding {
                padding-top: 3*$pd-xs;
            }

            &:hover {
                border: 1px solid $clr-purple;
                padding: 7*$pd-xs 14*$pd-xs;
                background-color: $clr-purple-light;
                cursor: pointer;

                &.smallPadding {
                    padding-top: 2*$pd-xs;
                }
            }

            &.selected {
                border: 2px solid;
                padding: 6*$pd-xs 13*$pd-xs;
                background-color: $clr-purple-light;

                &.smallPadding {
                    padding-top: $pd-xs;
                }
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    #timetable {
        width: auto;
        min-width: auto;
        flex-wrap: wrap;
        overflow-x: auto;

        #timetableHeader, #timetableContent {
            min-width: 800px;
        }

        #timetableContent {
            .eventTop {
                display: flex;
                flex-direction: column;
            }

            .eventDescription {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    #timetable {
        #timetableHeader, #timetableContent {
            min-width: 800px;
        }

        #timetableContent {
            .eventTop {
                display: flex;
                flex-direction: column;
            }

            .eventDescription {
                display: none;
            }
        }
    }
}
