/* __COLORS__ */
$clr-purple-darker: #272142;
$clr-purple-dark: #3a3163;
$clr-purple: #4d4184;
$clr-purple-light: #9188b9;
$clr-purple-lighter: #c1bbdc;
$clr-purple-lightest: #f4f1f8;

$clr-yellow: #ffd700;

$clr-grey-lighter: #5c6060;
$clr-grey: #3F4444;
$clr-grey-darker: #363a3a;

@function tinted($color, $level: 50) {
    @return mix(white, $color, $level);
}

@function shaded($color, $level: 50) {
    @return mix(black, $color, $level);
}

@function faded($color, $level: 0.4) {
    @return rgba($color, $level);
}


/* __MARGIN__ */
$mg-xs: 1px;
$mg-s: 5px;
$mg-m: 20px;
$mg-l: 50px;
$mg-xl: 100px;


/* __PADDING__ */
$pd-xs: 1px;
$pd-s: 5px;
$pd-m: 20px;
$pd-l: 50px;
$pd-xl: 100px;


/* __FLEX__ */
%flex-col {
    display: flex;
    flex-direction: column;
}

%flex-col-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

%flex-row {
    display: flex;
    flex-direction: row;
}

%flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

