@import '../variables';

%section {
    p {
        padding: $pd-s $pd-m;
        text-align: center;
    }
}

#section-bbq {
    @extend %section;
    @extend %flex-col-center;

    button.downloadBtn {
        text-align: center;
        outline: 0;
        margin: 25px;
        padding: 10px 35px;
        border-radius: 50px;

        color: $clr-purple-dark;
        border: 4px solid $clr-purple-dark;
        background-color: $clr-purple-lightest;
        font-weight: 600;

        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            background-color: faded($clr-purple-lighter);
        }

        &:active {
            transform: translateY(3px)
        }
    }
}

#section-lineup {
    @extend %section;
    overflow-x: hidden;

    h1 {
        width: 500px;
        max-width: 100vw;
        margin: 0 auto;
    }

    .daySelector {
        @extend %flex-row-center;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        justify-content: center;

        a {
            text-align: center;
            font-size: 90%;
            outline: 0;
            margin: $mg-m;
            padding: $pd-s $pd-m;
            border-radius: 50px;

            color: $clr-purple-dark;
            border: 4px solid $clr-purple-dark;
            background-color: $clr-purple-lightest;
            text-decoration: none;
            font-weight: 600;

            cursor: pointer;
            transition: all 0.3s ease;

            &:hover, &.selected {
                background-color: $clr-purple-dark;
                color: white;
            }

            &:active {
                transform: translateY(3px)
            }
        }
    }
}

