@import '../variables';

#introduction {
    @extend %flex-col-center;
    min-height: 400px;

    h1 {
        margin: $mg-l;
    }

    p {
        padding: $pd-s $pd-m;
        text-align: center;
    }
}
